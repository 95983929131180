<template>
  <div class="site-consultant-form">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
          
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>
      </div>

      <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
        <p>Вы получили скидку на мебель 20%. Наш специалист свяжется с Вами в течении рабочего дня для уточнения времени и деталей встречи</p>
      </b-notification>

      <div class="form-wrapper" v-if="isNotSuccess">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
        <input type="hidden" name="WEB_FORM_ID" :value="formId">

        <validation-provider v-slot="v" rules="required" name="Мебель" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }"  class="mb-4" custom-class="has-text-white" label="Какая мебель Вас интересует ? *">
            <b-select name="form_text_61" placeholder="Какая мебель Вас интересует ? *" v-model="formData.product" expanded>
              <option v-for="option in products" :selected="option.selected" :key="option.id" :value="option.name">
                {{option.name}}
              </option>
            </b-select>
          </b-field>

        </validation-provider>


        <validation-provider v-slot="v" rules="required" name="Ваше имя" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }"  class="mb-4" label="Ваше имя *"  custom-class="has-text-white">
            <b-input name="form_text_59" v-model="formData.name"></b-input>
          </b-field>

        </validation-provider>
             <validation-provider v-slot="v" :rules="{ required: true, regex: /^\d{3}\s\d{3}-\d{2}-\d{2}/ }" name="Телефон" slim>
                <b-field  :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }"  class="mb-4"  label="Номер телефона *"  custom-class="has-text-white">
                  <p class="control">
                    <b-button class="is-danger">+7</b-button>
                  </p>
                  <b-input v-mask="'### ###-##-##'" native-type="tel" name="form_text_60" placeholder="000 000-00-00" v-model="formData.phone" expanded></b-input>
                </b-field>
                <p class="has-text-danger is-size-7" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>
   
 
           <div class="controls">
            <b-button type="is-danger is-fullwidth is-uppercase" native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">Активировать</b-button>
          </div>
       

            <p class="help has-text-grey-light">
              Отправляя сообщение вы принимаете <a target="_blank" href="https://docs.google.com/document/d/1F22p5a1L2stCqcDDhDRKwmuM4aK9_MKt9bk7bPXoLdg/">Положение на обработку персональных данных и получение информации.</a>. Если вы не
              согласны <a target="_blank" href="https://docs.google.com/document/d/1F22p5a1L2stCqcDDhDRKwmuM4aK9_MKt9bk7bPXoLdg/">с условиями
              положения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.
            </p>
          

         
        </form>

      </div>
    </validation-observer>

    <!--
    /**
      * vue-portal позволяет отрисовать виджет/компонент
      * в любом месте приложения или вне приложения на странице.
      *
      * Преимущества перед обычным монтированием, как изолированное
      * приложение, в том, что сохранятся возможность влиять на
      * поведение компонента.
      * <MountingPortal mountTo=".feedback-portal" multiple>
      *   <b-button @click.prevent="click">Обратная связь</b-button>
      * </MountingPortal>
      */
    -->
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  mixins: [FormCommonMixin],
  props:{
     /**
     * Адрес страницы продукта
     * на сайте
     */
   /* pageUrl: {
      type: String,
      required: true,
      default: ''
    },*/
  },
  data() {
    return {
      /**
       * Без указания правильного идентификатора
       * данные не будут сохранены. И идентификатор формы и
       * названия полей должны строго соответствовать настройкам
       * сайта.
       */
      formId: 7,
      products: [
        { id: 1, name: 'полностью квартира/дом', active: true },
        { id: 2, name: 'кухонный гарнитур', active: true },
        { id: 3, name: 'гардеробная', active: true },
        { id: 4, name: 'прихожая', active: true },
        { id: 5, name: 'другое', active: true },

      ],

      /**
       * Набор стандартных полей создается
       * в "миксине" src/mixins/form-common-mixins.js
       *
       * Чтобы переопределить или дополнить данные,
       * надо создать данные непосредственно здесь
       * в компоненте. Если создавать одинаковые ключи,
       * они будут переопределены, если новые ключи, то
       * данные будут объединены.
       *
       * Ключ с данными формы - `formData`,
       * в нем добавлены часто используемые в формах ключи
       *
       * - name
       * - email
       * - phone
       * - message
       * - file
       *
       * Например чтобы добавить поле Адрес:
       *
       * ```js
       * data() {
       *   ...
       *   formData: {
       *     address: ''
       *   }
       *   ...
       * }
       *
       * Все данные будут объединены вместе. Конечный объект
       * будет содержать все ключи
       *
       * ```js
       * {
       *    name: '',
       *    email: '',
       *    phone: '',
       *    message: '',
       *    file: '',
       *    address: ''
       * }
       * ```
       *
       * Важно понимать, что на сервер отправляются все поля
       * формы, но сохраняются только поля, которые имеют
       * идентификаторы битрикс.
       *
       */
      formData: {
        product: ""
      },

      /**
       * Возможность заполнять демо-данными
       * форму по нажатию комбинаций клавиш.
       */
      demoShortcuts: false,

      /**
       * демо данные для тестирования
       *
       * Для "ручного" тестирования формы, чтобы каждый раз
       * не набирать данные в полях формы. Можно использовать
       * демо данные. Необходимо создать все ключи/поля формы,
       * соответствующие `formData`.
       *
       */
      demo: {
        /**
         * набор для проверки - CTRL+.
         */
        valid: {
          name: 'Demo Name',
          email: 'demo@diera.ru',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        },
        /**
         * набор для проверки ошибок - CTRL+,
         */
        invalid: {
          name: 'Demo Name',
          email: 'demo@diera',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        }
      }
    }
  },
  methods: {
    /**
     * при рекурсивных ссылках
     * больше проблем чем пользы
     *
     * - <MountingPortal /> вызывается каждый раз при открытии формы
     * - Надо задавать имя и рег. компонент глобально
     *
     * @todo это только пример (НЕ использовать)
     */
    // click() {
    //   this.$buefy.modal.open({
    //     component: 'consultant-form'
    //   })
    // }
  }
})
</script>

<style lang="sass" scoped>
@import 'helpers'
.site-consultant-form
  .help
    a 
      color: $grey-light
  
</style>
